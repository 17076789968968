import axios from "axios";
import store from "@/store";
import router from "@/router";
import {Buffer} from "buffer";

class ContenidoLogic {
    static instance = null;
    static createInstance() {
        var object = new ContenidoLogic();
        return object;
    }

    static getInstance() {
        if (!ContenidoLogic.instance) {
            ContenidoLogic.instance = ContenidoLogic.createInstance();
        }
        return ContenidoLogic.instance;
    }

    constructor() {
    }

    async editar(objConfiguracion){
        try {
            // Dynamic Imports
            var {default: HTTPHelper} = await import('@/helpers/http.helper');
            var {default: CommonHelper} = await import('@/helpers/common.helper');

            var ENV_APIPATH = process.env.APIPATH;
            var ENV_APIDEBUG = CommonHelper.parseBool(process.env.APIDEBUG);

            var currentRoute = router.currentRoute;
            var API_ACCESSTYPE = currentRoute.meta.apiAccessType;

            var formData = new FormData();
            formData.append('mensajeInicio', objConfiguracion.mensajeInicio);
            formData.append('quienesSomos', objConfiguracion.quienesSomos);
            formData.append('mision', objConfiguracion.mision);
            formData.append('vision', objConfiguracion.vision);
            formData.append('email', objConfiguracion.emailContacto);
            formData.append('telefonoFijo', objConfiguracion.telefonoFijo);
            formData.append('telefonoCelular', objConfiguracion.telefonoCelular);
            formData.append('direccion', objConfiguracion.direccion);
            formData.append('contactoFileImagen', objConfiguracion.contactoFileImagen);
            formData.append('urlFacebook', objConfiguracion.urlFacebook);
            formData.append('urlYoutube', objConfiguracion.urlYoutube);
            formData.append('urlInstagram', objConfiguracion.urlInstagram);

            var urlRequest = ENV_APIPATH+"/configuracion/website/contenido/editar";
            var httpResponse = await HTTPHelper.httpPOST(urlRequest,formData,API_ACCESSTYPE,ENV_APIDEBUG);

            return httpResponse;

        } catch (error) {
            throw Error("[ContenidoLogic] editar()::ERROR >>> "+error);
        }
    }

    async detalle(){
        try {
            // Dynamic Imports
            var {default: HTTPHelper} = await import('@/helpers/http.helper');
            var {default: CommonHelper} = await import('@/helpers/common.helper');

            var ENV_APIPATH = process.env.APIPATH;
            var ENV_APIDEBUG = CommonHelper.parseBool(process.env.APIDEBUG);

            var currentRoute = router.currentRoute;
            var API_ACCESSTYPE = currentRoute.meta.apiAccessType;

            var urlRequest = ENV_APIPATH+"/configuracion/website/contenido/detalle";

            var objRequest = {}

            var httpResponse = await HTTPHelper.httpPOST(urlRequest,objRequest,API_ACCESSTYPE,ENV_APIDEBUG);

            return httpResponse;

        } catch (error) {
            throw Error("[ContenidoLogic] detalle()::ERROR >>> "+error);
        }
    }
}

const instance = ContenidoLogic.getInstance();
export default instance;